// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import * as $ from 'jquery';
window.$ = $;

import 'bootstrap/dist/js/bootstrap.min';
import '../styles/application';
import '../libs/jquery.mask.min';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const checkTimeout = async () => {
  const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };

  const response = await fetch(`/timeout`, {
    headers: {
      'X-CSRF-Token': token.content
    }
  });
  const json = await response.json();

  if (json.status == 'expired') {
    window.location.href = '/login';
  }
};

$(document).on('turbolinks:load', function() {
  $('.date').mask('00/00/0000');
  $('.only_numbers').mask('0#');

  // const checkAgainAt = 3 * 60 * 1000;

  // setInterval(() => {
  //   checkTimeout();
  // }, checkAgainAt);
});

// ServiceWorker
if (navigator.serviceWorker) {
  document.addEventListener('turbolinks:load', () => {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
      .then(function(reg) {
        console.log('[Companion]', 'Service worker registered!');
      });
  });
};
